




















































































































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'
import Spinner from '@/components/misc/Spinner.vue'
import * as bip39 from 'bip39'
import PaperWallet from '@/components/modals/PaperWallet/PaperWallet.vue'
import VerifyMnemonic from '@/components/modals/VerifyMnemonic.vue'
import MnemonicCopied from '@/components/CreateWalletWorkflow/MnemonicCopied.vue'
import MnemonicPhrase from '@/js/wallets/MnemonicPhrase'
import { WalletNameType, WalletType } from '@/js/wallets/types'

@Component({
    components: {
        Spinner,
        VerifyMnemonic,
        MnemonicCopied,
        PaperWallet,
    },
})
export default class CreateWalletStep extends Vue {
    @Prop() changestep: any
    isLoad: boolean = false
    keyPhrase: MnemonicPhrase | null = null
    isSecured: boolean = false
    isVerified: boolean = false
    created() {
        this.createKey()
    }
    get canVerify(): boolean {
        return this.isSecured ? true : false
    }

    get verificatiionColor() {
        return this.isVerified ? '#a9efbf' : '#F5F6FA'
    }

    createKey(): void {
        this.isSecured = false
        let mnemonic = bip39.generateMnemonic(256)
        this.keyPhrase = new MnemonicPhrase(mnemonic)
    }

    get canSubmit(): boolean {
        // if(!this.rememberValid) return false;
        return true
    }
    get activeWallet(): WalletType | null {
        return this.$store.state.activeWallet
    }
    get walletType(): WalletNameType {
        let wallet = this.activeWallet
        if (!wallet) return 'mnemonic'
        return wallet.type
    }
    verifyMnemonic() {
        // @ts-ignore
        this.$refs.verify.open()
    }
    $refs!: {
        // modal: Modal
        print_modal: PaperWallet
    }
    viewPrintModal() {
        this.$refs.print_modal.open()
    }
    async complete() {
        await this.access()
        this.isLoad = false
        this.isVerified = true
    }
    async access(): Promise<void> {
        if (!this.keyPhrase) return
        this.isLoad = true
        let parent = this
        return await parent.$store.dispatch('accessWallet', this.keyPhrase!.getValue())
    }
    completeFirstStep() {
        this.$emit('changestep', 2)
    }
}
