


























import 'reflect-metadata'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Modal extends Vue {
    @Prop({ default: 'Modal Title' }) title!: string
    @Prop({ default: '' }) subtitle!: string
    @Prop({ default: true }) can_close!: boolean
    @Prop({ default: false }) icy!: boolean

    isActive: boolean = false

    destroyed() {
        setTimeout(() => (document.body.style.overflow = 'initial'), 1000)
    }

    public open() {
        this.isActive = true
        document.body.style.overflow = 'hidden'
    }

    bgclick() {
        if (this.can_close) {
            this.close()
        }
    }

    public close() {
        this.$emit('beforeClose')
        this.isActive = false
        document.body.style.overflow = 'initial'
    }
}
