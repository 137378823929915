
























































































































































































import { WHITE_LIST } from '@/constants'
import MnemonicWallet from '@/js/wallets/MnemonicWallet'
import { SingletonWallet } from '@/js/wallets/SingletonWallet'
import { WalletNameType } from '@/js/wallets/types'
import { getPublicKey } from '@/kyc_api'
import axios from 'axios'
import { Component, Vue, Watch } from 'vue-property-decorator'
import InputField from '../components/Saft/InputField.vue'
import MultiSigCheckbox from '../components/Saft/MultiSigCheckbox.vue'
import SaftCheckbox from '../components/Saft/SaftCheckbox.vue'
import { component as VueNumber } from '@coders-tm/vue-number-format'
import { Prop } from 'vue-property-decorator'

interface User {
    company: string
    name: string
    surname: string
    street: string
    street2: string
    zip: string
    city: string
    country: string
    email: string
    phone: string
    agree: boolean
    multisig: boolean
    purchaseAmount: number
    preferredCurrency: string
}

@Component({
    components: { SaftCheckbox, InputField, MultiSigCheckbox, VueNumber },
})
export default class Saft extends Vue {
    @Prop() name!: string
    @Prop() email!: string
    @Prop() phone!: string
    @Prop() surname!: string
    @Prop() purchaseAmount!: number
    nameRegex = /^([^<>()|[\]\\.,;:@\\"0-9]|(\\".+\\")){2,64}$/
    emailRegex = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    phoneRegex = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{3,9}$/
    purchaseAmountRegex = /^(?:1000(?:[.,]0*)?|[1-9]\d{3,}(?:[.,]\d+)?)$/
    isLoading = false
    background = ''
    price = 123.45
    number = {
        decimal: '.',
        separator: ',',
        prefix: '',
        precision: 4,
        masked: false,
    }
    whiteList = WHITE_LIST
    error: {
        name: boolean
        surname: boolean
        city: boolean
        email: boolean
        phone: boolean
        purchaseAmount: string
        preferredCurrency: boolean
    } = {
        name: false,
        surname: false,
        city: false,
        email: false,
        phone: false,
        purchaseAmount: '',
        preferredCurrency: false,
    }
    submitted: boolean = false
    user: User = {
        company: '',
        name: '',
        surname: '',
        street: '',
        street2: '',
        zip: '',
        city: '',
        country: '',
        email: '',
        phone: '',
        agree: false,
        multisig: false,
        purchaseAmount: 0,
        preferredCurrency: '',
    }
    created() {
        localStorage.removeItem('Email')
        localStorage.removeItem('Phone')
    }
    get walletType(): WalletNameType {
        return this.wallet.type
    }
    get wallet() {
        let wallet: MnemonicWallet = this.$store.state.activeWallet
        return wallet
    }

    get formattedPurchaseAmount(): string {
        const amount = this.user.purchaseAmount / 0.15
        const result = amount.toFixed(2).endsWith('.00')
            ? Math.floor(amount)
            : Number(amount.toFixed(2))

        return new Intl.NumberFormat('en-US').format(result)
    }

    get formattedReward(): string {
        const reward = (this.user.purchaseAmount / 0.15) * 0.06
        const result = reward.toFixed(2).endsWith('.00')
            ? Math.floor(reward)
            : Number(reward.toFixed(2))

        return new Intl.NumberFormat('en-US').format(result)
    }

    @Watch('user.purchaseAmount')
    onPurchaseAmountChange() {
        if (this.user.purchaseAmount < 1000)
            this.error.purchaseAmount = 'Purchase amount must be at least 1000'
        else this.error.purchaseAmount = ''
    }
    validatePurchaseAmount(value: any) {
        const maxValue = 9999999
        if (value > maxValue) {
            this.user.purchaseAmount = maxValue
        }
    }
    get privateKeyC(): string | null {
        if (this.walletType === 'ledger') return null
        let wallet = this.wallet as SingletonWallet | MnemonicWallet
        return wallet.ethKey
    }
    handleChange({ value, type }: { value: string; type: string }) {
        switch (type) {
            case 'company name (please only provide if the company is the buyer)':
                this.user.company = value
                break
            case 'name':
                this.user.name = value
                this.error.name = !this.nameRegex.test(value)
                break
            case 'surname':
                this.user.surname = value
                this.error.surname = !this.nameRegex.test(value)
                break
            case 'city':
                this.user.city = value
                this.error.city = !this.nameRegex.test(value)
                break
            case 'email':
                this.user.email = value
                this.error.email = !this.emailRegex.test(value)
                break
            case 'street':
                this.user.street = value
                break
            case 'street2':
                this.user.street2 = value
                break
            case 'zip code':
                this.user.zip = value
                break
            case 'preferred payment currency':
                this.user.preferredCurrency = value
                break
            default:
                break
        }
    }

    get submitUserDataDisabled(): boolean {
        return (
            !this.user.name ||
            !this.user.surname ||
            !this.user.street ||
            !this.user.zip ||
            !this.user.city ||
            !this.user.country ||
            !this.user.email ||
            !this.user.agree ||
            !this.user.purchaseAmount ||
            !this.user.preferredCurrency ||
            this.user.purchaseAmount < 1000 ||
            this.error.name ||
            this.error.surname ||
            this.error.city ||
            this.error.email ||
            this.error.purchaseAmount != ''
        )
    }

    async submitSaftForm(e: Event) {
        e.preventDefault()
        if (this.submitUserDataDisabled) return
        else {
            // saving pchain address
            let wallet: MnemonicWallet = this.$store.state.activeWallet
            localStorage.setItem('P-chain-address', wallet.getCurrentAddressPlatform())
            // saving the phone and email in local storage to be used in the KYC process
            localStorage.setItem('Email', this.user.email)
            localStorage.setItem('Phone', this.user.phone)
            if (this.privateKeyC) {
                // getPublicKey
                const publicKey = getPublicKey(this.privateKeyC)
                // put the send email request here
                axios.post('https://wallet-wizard-mailer.camino.network/email', {
                    ...this.user,
                    pChainAddress: wallet.getCurrentAddressPlatform(),
                    publicKey,
                    wizard: true,
                })
            }
        }
        let wallet: MnemonicWallet = this.$store.state.activeWallet
        this.submitted = true
        this.$emit('update:name', this.user.name)
        this.$emit('update:surname', this.user.surname)
        this.$emit('update:email', this.user.email)
        this.$emit('update:phone', this.user.phone)
        this.$emit('update:purchaseAmount', this.user.purchaseAmount)
        this.$emit('update:pchainAddress', wallet.getCurrentAddressPlatform())
        this.$emit('changestep', 3)
    }
}
