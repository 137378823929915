









































import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'
import PChainSaft from './PChainSaft.vue'

@Component({
    name: 'home',
    components: { PChainSaft },
})
export default class KYCWalletStepper extends Vue {
    step = 1
    pchainAddress = ''
    mounted() {}

    changeStep(s: number) {
        this.step = s
    }
}
