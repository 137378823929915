











































































import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'
import HomeNextStep from './HomeNextStep.vue'
import KYCWalletStepper from './KYCWalletStepper.vue'
import Modal from '@/components/modals/Modal.vue'

@Component({
    name: 'home',
    components: { HomeNextStep, KYCWalletStepper, Modal },
})
export default class Home extends Vue {
    startingCreateWallet = false
    startingAlreadyHaveWallet = false
    accept = false
    hasKYCWallet = false

    $refs!: {
        modal: Modal
    }

    open() {
        this.$refs.modal.open()
    }
    close() {
        this.$refs.modal.close()
    }

    mounted() {
        this.startingCreateWallet = false
        localStorage.removeItem('Email')
        localStorage.removeItem('Phone')
        this.open()
    }

    closeWithYes() {
        this.hasKYCWallet = true
        this.close()
        this.startingAlreadyHaveWallet = true
    }

    async goToStepper() {
        if (this.hasKYCWallet) {
            this.startingAlreadyHaveWallet = true
            return
        } else if (this.accept) {
            this.startingCreateWallet = true
        }
    }
}
