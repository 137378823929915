









































































import 'reflect-metadata'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Modal from '@/components/modals/Modal.vue'
import { WalletType } from '@c4tplatform/camino-wallet-sdk'
import WalletCreated from './WalletCreated.vue'
import { generateToken } from '@/kyc_api'
import { KYC_VARIANT } from '@/constants'
import MnemonicWallet from '@/js/wallets/MnemonicWallet'
import { SingletonWallet } from '@/js/wallets/SingletonWallet'
import { WalletNameType } from '@/js/wallets/types'
import snsWebSdk from '@sumsub/websdk'
import axios from 'axios'

@Component({
    components: {
        Modal,
        WalletCreated,
    },
})
export default class KycVerification extends Vue {
    @Prop() walle!: WalletType
    @Prop() verificationCompleted!: boolean
    @Prop() name!: string
    @Prop() surname!: string
    @Prop() email!: string
    @Prop() phone!: string
    @Prop() purchaseAmount!: number
    @Prop() pchainAddress!: string
    submitted: boolean = false
    walletCreated: boolean = this.verificationCompleted ? true : false
    isLoading = false
    background: string = ''
    verficationCompleted: boolean = false
    get wallet() {
        let wallet: MnemonicWallet = this.$store.state.activeWallet
        return wallet
    }
    get walletType(): WalletNameType {
        return this.wallet.type
    }
    get formattedPurchaseAmount(): string {
        const amount = this.purchaseAmount / 0.15
        const result = amount.toFixed(2).endsWith('.00')
            ? Math.floor(amount)
            : Number(amount.toFixed(2))

        return new Intl.NumberFormat('en-US').format(result)
    }

    get formattedReward(): string {
        const reward = (this.purchaseAmount / 0.15) * 0.06
        const result = reward.toFixed(2).endsWith('.00')
            ? Math.floor(reward)
            : Number(reward.toFixed(2))

        return new Intl.NumberFormat('en-US').format(result)
    }
    async getNewAccessToken() {
        if (this.privateKeyC) {
            const result = await generateToken(this.privateKeyC, KYC_VARIANT.KYC_BASIC)
            return result.access_token
        }
        return ''
    }
    launchWebSdk(accessToken: string, applicantEmail: any, applicantPhone: any) {
        let snsWebSdkInstance = snsWebSdk
            .init(accessToken, () => this.getNewAccessToken())
            .withConf({
                email: applicantEmail,
                phone: applicantPhone,
                uiConf: {
                    customCssStr: this.background,
                },
            })
            .withOptions({ addViewportTag: false, adaptIframeHeight: true })
            .on('idCheck.applicantStatus', async (applicantStatus) => {
                await this.$store.dispatch('Accounts/updateKycStatus')
                if (applicantStatus.reviewStatus === 'completed') {
                    this.verficationCompleted = true
                    axios.post('https://wallet-wizard-mailer.camino.network/kyc', {
                        email: `${this.email}`,
                        name: `${this.name} ${this.surname}`,
                        purchasedAmount: `${this.formattedPurchaseAmount}`,
                        rewardAmount: `${this.formattedReward}`,
                        pchainAddress: `${this.pchainAddress}`,
                    })
                }
            })
            .build()
        setTimeout(() => {
            snsWebSdkInstance.launch('#sumsub-websdk-container')
        }, 500)
    }

    @Watch('$root.theme', { immediate: true })
    onthemechange(val: string) {
        if (val === 'night') {
            this.background =
                ".step.active .line, .step.active .bullet:before, .radio-item .checkmark:after, .step.active.pending .bullet:before {\
    background-color: #149ded;\
}\
.accent {\
    color: #149ded;\
}\
.step .title, .title  {\
    color: #f5f5f5;\
}\
.step.active .title {\
    color: #149ded;\
    font-size: 14px;\
    font-weight: 400;\
}\
section {\
    border-radius: 12px;\
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;\
    background-color: #1e293b;\
}\
p , h3, h2, label, .markdown-instructions li , .markdown-instructions p,.line-form .line-form-item > .phone-input,\
.line-form .line-form-item > input{\
    color : #f5f5f5 !important;\
    font-size : 14px;\
}\
.document-examples, .upload {\
    gap : 10px;\
}\
.upload-payment-item {\
    margin : 0px;\
}\
.upload-payment-item .upload-item , .mobile-button{\
    border: 1px solid rgba(203, 213, 225, 0.12);\
    border-radius: 7px;\
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;\
}\
 .mobile-button h3{\
    color : #149ded !important;\
 }\
 button.submit,\
button[type='submit'] {\
    border-radius: 12px;\
    background-color: transparent;\
    background-image: none;\
    color: #149ded;\
    border: 1px solid #149ded;\
}\
button:active:not(:disabled):not(.disabled),\
button:hover:not(:disabled):not(.disabled):not(:active) {\
    box-shadow: none;\
}\
button.submit:active:not(:disabled),\
button.submit:hover:not(:disabled):not(.disabled):not(:active),\
button[type='submit']:active:not(:disabled),\
button[type='submit']:hover:not(:disabled):not(.disabled):not(:active) {\
     background-image: none;\
}\
button {\
    border-radius: 12px;\
    background-color: transparent;\
    font-weight: 600;\
    text-align: center;\
    color: #149ded;\
    border: 1px solid #149ded;\
}\
.line-form .line-form-item > span {\
    border-bottom: none;\
}\
button.submit .arrow, button[type=submit] .arrow {\
    margin-right: 0;\
    margin-left: 5px;\
}\
button .arrow {\
    margin-right: 5px;\
}\
.upload-item h4.requiredDoc:after {\
    color: #149ded;\
}\
.popup {\
    background: #1e293b !important;\
}\
.popup .message-content p {\
    color: #f5f5f5 !important;\
}\
.step.pending .bullet {\
    background-color: #f5f5f5;\
    background-image: none;\
    border-color: #f5f5f5;\
}\
.step.pending .line , .step.active .line, .step.success .line{\
    background-color: #149ded;\
}\
.step.success .bullet {\
    background-color: #149ded;\
    border-color: #f5f5f5;\
}\
.error-message.warn {\
    background-color: #0f172a;\
}\
.radio-item input:disabled~.checkmark:after {\
  background-color: #149ded;\
}\
.document-status {\
    background-color: transparent !important;\
}\
"
            // 'body {background-color: var(--secondary-color) !important; min-height: 450px !important;} .line {background-color: black !important;}'
        } else {
            this.background =
                ".step.active .line, .step.active .bullet:before, .radio-item .checkmark:after, .step.active.pending .bullet:before {\
    background-color: #149ded;\
}\
.accent {\
    color: #149ded;\
}\
.step .title, .title  {\
    color: #0f172a;\
}\
.step.active .title {\
    color: #149ded;\
    font-size: 14px;\
    font-weight: 400;\
}\
section {\
    border-radius: 12px;\
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;\
    background-color: transparent;\
}\
p , h3, h2, label, .markdown-instructions li , .markdown-instructions p,.line-form .line-form-item > .phone-input,\
.line-form .line-form-item > input{\
    color : #0f172a !important;\
    font-size : 14px;\
}\
.document-examples, .upload {\
    gap : 10px;\
}\
.upload-payment-item {\
    margin : 0px;\
}\
.upload-payment-item .upload-item , .mobile-button{\
    border: 1px solid rgba(203, 213, 225, 0.12);\
    border-radius: 7px;\
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;\
}\
 .mobile-button h3{\
    color : #149ded !important;\
 }\
 button.submit,\
button[type='submit'] {\
    border-radius: 12px;\
    background-color: transparent;\
    background-image: none;\
    color: #149ded;\
    border: 1px solid #149ded;\
}\
button:active:not(:disabled):not(.disabled),\
button:hover:not(:disabled):not(.disabled):not(:active) {\
    box-shadow: none;\
}\
button.submit:active:not(:disabled),\
button.submit:hover:not(:disabled):not(.disabled):not(:active),\
button[type='submit']:active:not(:disabled),\
button[type='submit']:hover:not(:disabled):not(.disabled):not(:active) {\
     background-image: none;\
}\
button {\
    border-radius: 12px;\
    background-color: transparent;\
    font-weight: 600;\
    text-align: center;\
    color: #149ded;\
    border: 1px solid #149ded;\
}\
.line-form .line-form-item > span {\
    border-bottom: none;\
}\
button.submit .arrow, button[type=submit] .arrow {\
    margin-right: 0;\
    margin-left: 5px;\
}\
button .arrow {\
    margin-right: 5px;\
}\
.upload-item h4.requiredDoc:after {\
    color: #149ded;\
}\
.popup {\
    background: #e2e8f0 !important;\
}\
.popup .message-content p {\
    color: #0f172a !important;\
}\
.step.pending .bullet {\
    background-color: #0f172a;\
    background-image: none;\
    border-color: #0f172a;\
}\
.step.pending .line , .step.active .line, .step.success .line{\
    background-color: #149ded;\
}\
.step.success .bullet {\
    background-color: #149ded;\
    border-color: #0f172a;\
}\
.error-message.warn {\
    background-color: transparent;\
}\
.radio-item input:disabled~.checkmark:after {\
  background-color: #149ded;\
}\
.document-status {\
    background-color: transparent !important;\
}\
"
        }
    }
    get privateKeyC(): string | null {
        if (this.walletType === 'ledger') return null
        let wallet = this.wallet as SingletonWallet | MnemonicWallet
        return wallet.ethKey
    }

    async submitUserData() {
        // if (!this.userData.email || !this.userData.phone) return
        try {
            this.isLoading = true
            const accessToken = await this.getNewAccessToken()
            this.launchWebSdk(accessToken, this.email, this.phone)
            // this.userDataSubmitted = true
            this.submitted = true
        } finally {
            this.isLoading = false
        }
    }
    async completeSaftStep() {
        await this.submitUserData()
    }
    doneWalletCreation() {
        this.walletCreated = true
    }
}
