
















































import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'

import Modal from '../Modal.vue'
import { SaveAccountInput } from '@/store/types'
import { iUserAccountEncrypted } from '@/store/types'
import Identicon from '@/components/misc/Identicon.vue'

@Component({
    components: {
        Identicon,
        Modal,
    },
})
export default class SaveAccountModal extends Vue {
    password: string = ''
    password_confirm: string = ''
    isLoading: boolean = false
    isDestroyed: boolean = false
    err: any = ''
    accountName = ''
    existsInLocalStorage: boolean = false
    index: number = 0
    foundAccount: iUserAccountEncrypted | null = null
    $refs!: {
        modal: Modal
    }

    get canSubmit() {
        if (this.error !== null) return false
        return true
    }

    get error() {
        if (!this.password) return this.$t('keys.password_validation')
        if (!this.password_confirm) return this.$t('keys.password_validation2')
        if (this.accountName.length < 1) return this.$t('keys.account_name_required')
        if (this.password.length < 9) return this.$t('keys.password_validation')
        if (this.password !== this.password_confirm) return this.$t('keys.password_validation2')

        return null
    }

    async submit(): Promise<void> {
        this.isLoading = true
        let pass = this.password
        let accountName = this.accountName

        let input: SaveAccountInput = {
            accountName: accountName,
            password: pass,
        }
        await this.$store.dispatch('Accounts/saveAccount', input)

        this.isLoading = false
        this.onsuccess()
    }

    destroyed() {
        this.isDestroyed = true
    }

    onsuccess() {
        this.$store.dispatch('Notifications/add', {
            title: 'Account Saved',
            message: 'Your keys are now stored under a new local account.',
            type: 'info',
        })
        this.close()
    }

    clear() {
        this.password = ''
        this.password_confirm = ''
        this.accountName = ''
        this.err = ''
    }
    close() {
        this.clear()
        if (!this.isDestroyed) this.$refs.modal.close()
    }

    open() {
        this.$refs.modal.open()
    }

    get name(): string[] {
        return this.$store.getters['Accounts/name']
    }
}
