







































































































import Navbar from '@/components/SimpleNavbar.vue'
import { Component, Vue } from 'vue-property-decorator'
@Component({
    components: { Navbar },
})
export default class DefaultLayout extends Vue {}
