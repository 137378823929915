

































import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'
import Steper from './Steper.vue'

@Component({
    name: 'home',
    components: { Steper },
})
export default class Home extends Vue {
    starting = false
    step = 1
    mounted() {
        this.starting = false
        localStorage.removeItem('Email')
        localStorage.removeItem('Phone')
    }

    goToStepper() {
        // go to stepper view whitout router
        this.starting = true
    }
}
